<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <h4 qid="maintenance-create-s-title">
      {{$t('systems.create.title')}}
    </h4>

    <div class="card">
      <div class="card-body">
        <div class="form-centered">
          <b-alert v-if="error.length"
                   show
                   variant="danger"
                   qid="create-s-error"
          >
            {{ error }}
          </b-alert>
          <b-form @submit="formSubmit" qid="create-s-form">
            <div class="form-container">
                <b-form-group>
                  <label qid="create-s-name-label">
                    {{ $t('systems.create.name_label') }}
                  </label>
                  <b-form-input
                    qid="create-s-form-name"
                    type="text"
                    v-model="sForm.name"
                    :maxLength="255"
                    required
                    :placeholder="$t('systems.create.name_placeholder')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label qid="create-s-description-label">
                    {{ $t('systems.create.description_label') }}
                  </label>
                  <b-form-textarea
                    qid="create-s-form-description"
                    v-model="sForm.description"
                    rows="6"
                    max-rows="6"
                    :placeholder="$t('systems.create.description_placeholder')"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group>
                  <label qid="create-s-users-label">
                    {{ $t('systems.create.owners_label') }}
                  </label>
                  <multiselect
                      v-model="selectedUsers"
                      :options="users"
                      :multiple="true"
                      :taggable="false"
                      :select-label="$t('system.dropdown_select_label')"
                      :selected-label="$t('system.dropdown_selected_label')"
                      :deselect-label="$t('system.dropdown_deselect_label')"
                      track-by="slug"
                      :placeholder="$t('systems.create.owners_placeholder')"
                      label="name"
                      @remove="removeUserFromDropdown"
                      @search-change="loadUsers"
                      qid="create-s-users-select"
                  >
                    <template slot="tag" slot-scope="props">
                      <span></span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <Avatar :object="props.option" size="xs"></Avatar>
                      <span class="ml-2">
                        {{ props.option.name }}
                      </span>
                    </template>
                  </multiselect>
                  <div class="form-control auto-height mt-1" v-if="selectedUsers && selectedUsers.length">
                    <div v-for="(user, index) in selectedUsers" class="mt-1 mb-1">
                      <div class="row">
                        <div class="col-sm-1">
                          <font-awesome-icon
                            icon="star"
                            :class="{'color-yellow': user.owner, 'text-light': !user.owner}"
                            class="cursor-pointer"
                            @click="setOwner(index)"
                          />
                        </div>
                        <div class="col-sm-10">
                          <Avatar :object="user" size="xs" :noOwner="true"></Avatar>
                          <span class="ml-2">{{ user.name }}</span>
                        </div>
                        <div class="col-sm-1">
                          <font-awesome-icon
                            icon="times"
                            class="cursor-pointer"
                            @click="removeUser(index)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="text-light">
                    * {{ $t('systems.create.owners_tip') }}
                  </span>
                </b-form-group>
                <b-form-group>
                  <label qid="create-s-pg-label">
                    {{ $t('systems.create.data_structure_label') }}
                  </label>
                  <b-form-checkbox
                    v-model="sForm.is_structured"
                    name="checkbox-1"
                    :value="1"
                    :unchecked-value="0"
                  >
                    {{ $t('systems.create.structured') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="sForm.is_unstructured"
                    name="checkbox-2"
                    :value="1"
                    :unchecked-value="0"
                  >
                    {{ $t('systems.create.unstructured') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="sForm.is_physical"
                    name="checkbox-3"
                    :value="1"
                    :unchecked-value="0"
                  >
                    {{ $t('systems.create.physical') }}
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <label qid="create-s-tags-label">
                    {{ $t('systems.create.tags_label') }}
                  </label>
                  <multiselect
                      v-model="selectedTags"
                      :options="tags"
                      :multiple="true"
                      :select-label="$t('system.dropdown_select_label')"
                      :selected-label="$t('system.dropdown_selected_label')"
                      :deselect-label="$t('system.dropdown_deselect_label')"
                      :taggable="true"
                      track-by="name_translated"
                      :placeholder="$t('systems.create.tags_placeholder')"
                      label="name_translated"
                      qid="create-do-tags-select"
                      @search-change="loadTags"
                      @tag="addTag"
                  ></multiselect>
                </b-form-group>

            </div>
            <div class="text-right">
              <b-button class="mt-3"
                        variant="secondary"
                        @click="goToList"
                        qid="create-s-cancel-button"
              >
                {{ $t('systems.create.cancel') }}
              </b-button>
              <b-button type="submit"
                        class="mt-3 ml-3"
                        variant="success"
                        :disabled="buttonDisabled"
                        qid="create-s-submit-button"
              >
                <span v-if="buttonDisabled" class="mr-1">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </span>
                {{ $t('systems.create.submit') }}
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'CreateSystem',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('systems.title'),
            to: '/systems'
          },
          {
            text: this.$t('systems.create.title'),
            active: true
          }
        ],
        sForm: {
          name: '',
          description: '',
          client:'',
          is_structured: 0,
          is_unstructured: 0,
          is_physical: 0
        },
        tags: [],
        selectedTags: [],
        error: '',
        users: [],
        selectedUsers: [],
        newTags: [],
        buttonDisabled: false
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadUsers()
        this.loadTags()
      }
    },
    methods: {
      goToList() {
        this.$router.push('/systems')
      },
      addTag(newTag) {
        const tag = {
          name: newTag,
          translatable: newTag,
          name_translated: newTag
        }
        this.selectedTags.push(tag)
        this.newTags.push(tag)
      },
      removeUser(index) {
        let selectedItem = this.selectedUsers[index]
        selectedItem.owner = false;
        this.$set(this.selectedUsers, index, selectedItem)
        // Remove item from selected users array
        this.selectedUsers.splice(index, 1);
      },
      removeUserFromDropdown(item) {
        item.owner = false;
      },
      setOwner(index) {
        let selectedItem = this.selectedUsers[index]
        _.forEach(this.selectedUsers, function (item) {
          item.owner = false;
        })
        selectedItem.owner = true;
        this.$set(this.selectedUsers, index, selectedItem)
      },
      formSubmit(evt) {
        let formData = new FormData();
        let files = document.querySelector('#file');
        this.buttonDisabled = true;
        let selectedOwner = false;

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        // Append list of users to FormData
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            formData.append("users[]", this.selectedUsers[i].id);
            if (this.selectedUsers[i].owner) {
              selectedOwner = true;
              formData.append("owner", this.selectedUsers[i].id);
            }
          }
        } else {
          selectedOwner = true;
        }

        if (this.selectedTags && this.selectedTags.length) {
          for (var i = 0; i < this.selectedTags.length; i++) {
            formData.append("tags[]", this.selectedTags[i].name_translated);
          }
        }

        formData.append("name", this.sForm.name);
        formData.append("description", this.sForm.description);
        formData.append("client", this.getClient.slug);

        formData.append("is_structured", this.sForm.is_structured);
        formData.append("is_unstructured", this.sForm.is_unstructured);
        formData.append("is_physical", this.sForm.is_physical);

        if (selectedOwner) {
          piincHttp.post('/systems', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function(response) {
            self.$toastr('success', self.$t('systems.create.success'))
            self.buttonDisabled = false;
            self.$router.push({ name: 'systems.view', params: { slug: response.data.slug }})
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              // Print whole array of actual errors beside message
              self.error = self.error + '\n\n' + JSON.stringify(error.data.errors);
            }
          })
        } else {
          this.error = this.$t('system.owners_error_message')
          this.buttonDisabled = false;
        }
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadTags(query) {
        let self = this;
        piincHttp.get('tags', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.tags = response.data
          if (self.tags && !self.tags.length && !query) {
            self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
          }
          for (var i = 0; i < self.newTags.length; i++) {
            self.tags.push(self.newTags[i]);
          }
        }, function() {});
      }
    },
    components: {
      Avatar
    },
    created () {
      if (this.getClient && this.getClient.slug) {
        this.loadUsers()
        this.loadTags()
      }
    }
  }
</script>
